import React, { Component, useState } from "react";
import { ArrowForwardIosOutlined } from "@mui/icons-material";
import { Typography } from "@mui/material";
const UnitsList = ({ units, handleSelectedUnit }) => {
  return (
    <>
      {units.length > 0 &&
        units.map((unit) => {
          return (
            <div
              key={unit.entry_id}
              className="unitsWrapper"
              onClick={() => handleSelectedUnit(unit)}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: 10,
                }}
              >
                <div>
                  <Typography variant="subtitle2">{unit.unitCode}</Typography>{" "}
                </div>
                <div style={{ flexGrow: 1 }}>
                  <Typography
                    variant="h4"
                    style={{ fontWeight: 600, fontSize: 12 }}
                  >
                    {unit.unitName}
                  </Typography>
                </div>
              </div>

              <div>
                <ArrowForwardIosOutlined
                  style={{ color: "grey", fontSize: 15 }}
                />
              </div>
            </div>
          );
        })}
    </>
  );
};

export default UnitsList;
