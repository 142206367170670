import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Typography,
  CircularProgress,
} from "@mui/material";
import { CloseOutlined, FileUploadOutlined } from "@mui/icons-material";

import UnitsList from "../components/courseUnits/UnitsList";
//import useHttp from "../hooks/useHttp";
import { basePath } from "../util/basePath";
import ExamList from "./ExamList";
const RegisteredUnits = ({ quizId }) => {
  const [registeredUnits, setRegisteredUnits] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [uploadingFile, setUploadingFile] = useState(false);
  const [errorUploadingFile, setErrorUploadingFile] = useState(null);
  const [successUploadingFile, setSuccessUploadingFile] = useState(false);
  const [currentSemPeriod, setCurrentSemPeriod] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [currentUnit, setCurrentUnit] = useState(null);
  //const [currentPeriod, getCurrentPeriod] = useState(null);

  const url = basePath.url;
  const dispatch = useDispatch();
  const user = useSelector((state) => {
    return state.logger.user;
  });

  const handleSelectedUnit = (unit) => {
    // alert(unit.entry_id);
    setCurrentUnit(unit);
    console.log(unit);
    setOpenModal(true);
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  const onChangeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const onClickHandler = () => {
    setUploadingFile(true);
    setErrorUploadingFile(true);
    setSuccessUploadingFile(false);
    const data = new FormData();

    data.append("admissionNo", user.admissionNo);
    data.append("quizId", quizId);
    data.append("file", selectedFile);
    axios
      .post("http://localhost:2000/api/upload", data, {
        // receive two    parameter endpoint url ,form data
      })
      .then((res) => {
        // then print response status
        if (res.data.message === "success") {
          setUploadingFile(false);
          setErrorUploadingFile(true);
          setSuccessUploadingFile(true);
        } else {
          setUploadingFile(false);
          setErrorUploadingFile(true);
          setSuccessUploadingFile(false);
        }
      })
      .catch((err) => {
        console.log(err.message);
        setUploadingFile(false);
        setErrorUploadingFile(true);
        setSuccessUploadingFile(false);
      });
  };

  useEffect(() => {
    fetch(`${url}current-period`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((response) => {
        //console.log(response.details.periodID);
        setCurrentSemPeriod(response.details.periodID);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (currentSemPeriod) {
      setError(null);
      setLoading(true);
      setRegisteredUnits([]);

      let postData = {
        admissionNo: user.admissionNo,
        periodId: currentSemPeriod,
      };

      fetch(`${url}registered-units`, {
        method: "POST",
        headers: {
          Accept: "application.json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(postData),
      })
        .then((res) => {
          if (res.status >= 400 && res.status <= 600) {
            throw new Error("Error Fetching Units");
          }
          return res.json();
        })
        .then((result) => {
          setRegisteredUnits(result.data);
          setError(null);
          setLoading(false);

          if (result.data.length < 1) {
            setError("No unit registered for the current semester");
            setLoading(false);
          }
        })
        .catch((err) => {
          setError(err);
          alert(err.message);

          setLoading(false);
          setRegisteredUnits([]);
        });
    }
  }, [currentSemPeriod]);

  useEffect(() => {}, []);
  return (
    <>
      <Dialog open={openModal} maxWidth="lg">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: 0,
            padding: 0,
            paddingLeft: 10,
            paddingRight: 15,
            borderBottom: "1px solid green",
            width: "100%",
          }}
        >
          <DialogTitle>Practical Exam File Upload</DialogTitle>

          <CloseOutlined onClick={closeModal} style={{ cursor: "pointer" }} />
        </div>
        <DialogContent>
          <div
            className="formWrapper"
            style={{ width: "850px", height: "auto", gap: 20 }}
          >
            <div
              style={{
                paddingBottom: "5px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography variant="subtitle1">
                {currentUnit?.unitCode}
              </Typography>
              <Typography variant="subtitle2" style={{ fontWeight: 600 }}>
                {currentUnit?.unitName}
              </Typography>
            </div>
            <div>
              <Typography variant="subtitle2" style={{ color: "green" }}>
                Please Browse and select the file to upload then click on the
                upload button
              </Typography>
            </div>
            <ExamList
              entry_id={currentUnit?.entry_id}
              unitId={currentUnit?.unitID}
              unitName={currentUnit?.unitName}
              unitCode={currentUnit?.unitCode}
              periodId={currentSemPeriod}
            />
            {/* <div className="formInput">
              <Typography variant="subtitle2">
                Please select File and Upload
              </Typography>
              <input type="file" name="file" onChange={onChangeHandler} />
            </div> */}
          </div>
          {/* {successUploadingFile ? (
            <div
              style={{
                padding: 10,
                width: "99%",
                margin: 10,
                boxSizing: "border-box",
                backgroundColor: "#def5ed",
                borderLeft: "3px solid green",
              }}
            >
              <Typography variant="subtitle2">
                Error Uploading File! Please try again
              </Typography>
            </div>
          ) : null}
          {errorUploadingFile ? (
            <div
              style={{
                padding: 10,
                width: "99%",
                margin: 10,
                boxSizing: "border-box",
                borderLeft: "3px solid crimson",
                backgroundColor: "#ffe3e3",
              }}
            >
              <Typography variant="subtitle2">
                Error Uploading File! Please try again
              </Typography>
            </div>
          ) : null}
          {uploadingFile ? (
            <CircularProgress color="secondary" />
          ) : (
            <Button
              variant="outlined"
              size="medium"
              color="primary"
              startIcon={<FileUploadOutlined size="medium" color="grey" />}
              onClick={onClickHandler}
            >
              Upload
            </Button>
          )} */}
        </DialogContent>
      </Dialog>
      <Typography
        variant="subtitle1"
        style={{
          fontWeight: 600,
          padding: 10,
          borderBottom: "1px solid green",
        }}
      >
        SCHOOL OF COMPUTER SCIENCE PRACTICAL EXAM FILE UPLOAD{" "}
      </Typography>
      <div
        style={{ display: "flex", flexDirection: "column", padding: "10px" }}
      >
        {loading ? <div>Loading....</div> : null}
        {error ? <div>{error}</div> : null}
        {registeredUnits.length > 0 ? (
          <UnitsList
            units={registeredUnits}
            handleSelectedUnit={handleSelectedUnit}
          />
        ) : null}
      </div>
    </>
  );
};

export default RegisteredUnits;
