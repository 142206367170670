import { useEffect, useState } from "react";
import { UseSelector, useSelector } from "react-redux/es/hooks/useSelector";
import { useNavigate } from "react-router-dom";
import { Typography, Button, CircularProgress } from "@mui/material";
import {
  ErrorOutlined,
  ArrowForward,
  Edit,
  Lock,
  LockOpen,
} from "@mui/icons-material";
import { basePath } from "../../util/basePath";
import styles from "../../styles/sidenav.module.css";
import axios from "axios";

import ErrorModal from "../../components/ErrorModal";

const InternshipList = () => {
  const [internshipList, setInternshipList] = useState(null);
  const [employeeDetails, setEmployeeDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentUserId, setCurrentUserId] = useState("");
  const [startDate, setStartDate] = useState("");

  const navigate = useNavigate();

  const user = useSelector((state) => {
    return state.logger.user;
  });

  useEffect(() => {
    setLoading(true);
    setError(null);

    // const aController = new AbortController();
    // fetch(`${basePath.url}internship/student`, {
    //   signal: aController.signal,
    //   method: "POST",
    //   body: JSON.stringify({ admissionNo: user.admissionNo }),
    //   headers: {
    //     Accept: "application/json",
    //     "Content-Type": "application/json",
    //   },
    // })
    //   .then((response) => {
    //     console.log(response);
    //     return response.json();
    //   })
    //   .then((result) => {
    //     console.log(`result: ${result.data.length}`);
    //     if (result.data.length > 0) {
    //       setInternshipList(result.data);

    //       setCurrentUserId(result.data[0].lecturer_assigned_id);
    //       setLoading(false);
    //       setError(null);
    //     } else {
    //       setLoading(false);
    //       setError(null);
    //     }
    //   })
    //   .catch((err) => {
    //     if (err.name !== "AbortError") {
    //       setLoading(false);
    //       setError(error);
    //     }
    //   });

    // return () => {
    //   aController.abort();
    // };

    const getList = async () => {
      const list = await axios.post(`${basePath.url}internship/student`, {
        data: user.admissionNo,
      });
      try {
        setInternshipList(list.data.data);
        if (list.data.data.length > 0) {
          // console.log(res.data.data);
          const user_id = internshipList[0].lecturer_assigned_id;

          // setCurrentUserId(user_id);
          if (list.data.data[0].lecturer_assigned_id) {
            const employee = await axios.get(
              `${basePath.url}users/get-by-user-id/${list.data.data[0].lecturer_assigned_id}`
            );

            if (employee.data.data.length > 0) {
              setEmployeeDetails(employee.data.data[0]);
            }
          }

          setLoading(false);
          setError(null);
        } else {
          setLoading(false);
          setError(null);
        }
      } catch (error) {
        setLoading(false);
        setError(error);
      }
    };
    getList();
  }, []);

  // useEffect(() => {
  //   //get lecturer assigned details
  //   const getEmployee = async () => {
  //     if (currentUserId) {
  //       const employee = await axios.get(
  //         `${basePath.url}users/get-by-user-id/${currentUserId}`
  //       );
  //       console.log(
  //         `employee details:${employee.data.data} user_id:${currentUserId}`
  //       );
  //       if (employee.data.data.length > 0) {
  //         setEmployeeDetails(employee.data.data);
  //       }
  //     }
  //   };

  //   getEmployee();
  // }, [internshipList]);

  return (
    <>
      {/* <ErrorModal /> */}
      <div className={styles.topContent}>
        <Typography
          variant="h6"
          style={{ fontWeight: 400, color: "#282727", padding: 10 }}
        >
          Internship
        </Typography>
      </div>
      {loading === true ? (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "20px",
            marginTop: "50px",
          }}
        >
          <CircularProgress />
        </div>
      ) : !internshipList || internshipList.length < 1 ? (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "20px",
            marginTop: "50px",
          }}
        >
          <ErrorOutlined style={{ fontSize: 50 }} color="warning" />
          <Typography variant="h6" style={{ textAlign: "center" }}>
            You have not added Internship Programs
          </Typography>
          <Button
            variant="outlined"
            color="success"
            size={"medium"}
            endIcon={<ArrowForward />}
            onClick={() => {
              navigate("/internship-add");
            }}
          >
            Add New Internship
          </Button>
        </div>
      ) : (
        <>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              gap: 3,
            }}
          >
            {internshipList[0].status === "OPEN" ? <LockOpen /> : <Lock />}
            <Typography
              variant="subtitle1"
              style={{ fontSize: 12, paddingRight: 10 }}
            >
              {internshipList[0].status.toLowerCase()}
            </Typography>
          </div>
          <div
            className={styles.mainContent}
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <div
                className="search_box"
                style={{ width: "auto", marginRight: 10 }}
              >
                <label style={{ fontSize: 14 }}>Organization :</label>
                <Typography
                  variant={"subtitle2"}
                  style={{ fontSize: 12, paddingLeft: 5 }}
                >
                  {internshipList[0].organization}
                </Typography>
              </div>

              <div
                className="search_box"
                style={{ width: "auto", marginRight: 10 }}
              >
                <label style={{ fontSize: 14 }}>Start Date :</label>
                <Typography
                  variant={"subtitle2"}
                  style={{ fontSize: 12, paddingLeft: 5 }}
                >
                  {new Date(internshipList[0].start_date).toDateString()}
                </Typography>
              </div>

              <div
                className="search_box"
                style={{ width: "auto", marginRight: 10 }}
              >
                <label style={{ fontSize: 14 }}>End Date :</label>
                <Typography
                  variant={"subtitle2"}
                  style={{ fontSize: 12, paddingLeft: 5 }}
                >
                  {new Date(internshipList[0].end_date).toDateString()}
                </Typography>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <div
                className="search_box"
                style={{ width: "auto", marginRight: 10 }}
              >
                <label style={{ fontSize: 14 }}>Payment Status :</label>
                <Typography
                  variant={"subtitle2"}
                  style={{ fontSize: 12, paddingLeft: 5 }}
                >
                  {internshipList[0].payment_status}
                </Typography>
              </div>

              <div
                className="search_box"
                style={{ width: "auto", marginRight: 10 }}
              >
                <label style={{ fontSize: 14 }}>Assessment Status :</label>
                <Typography
                  variant={"subtitle2"}
                  style={{ fontSize: 12, paddingLeft: 5 }}
                >
                  {internshipList[0].assessment_status}
                </Typography>
              </div>
            </div>
          </div>
          <div className={styles.mainContent}>
            <div className={styles.formSubContent}>
              <div className="formInput">
                <label style={{ fontSize: 14 }}>Country :</label>
                <Typography
                  variant="subtitle2"
                  style={{ fontSize: 12, paddingLeft: 5 }}
                >
                  {internshipList[0].country}
                </Typography>
              </div>
            </div>

            <div className={styles.formSubContent}>
              <div className="formInput">
                <label style={{ fontSize: 14 }}>County :</label>
                <Typography
                  variant="subtitle2"
                  style={{ fontSize: 12, paddingLeft: 5 }}
                >
                  {internshipList[0].county}
                </Typography>
              </div>
            </div>
            <div className={styles.formSubContent}>
              <div className="formInput">
                <label style={{ fontSize: 14 }}>Town: </label>
                <Typography
                  variant="subtitle2"
                  style={{ fontSize: 12, paddingLeft: 5 }}
                >
                  {internshipList[0].town}
                </Typography>
              </div>
            </div>
            <div className={styles.formSubContent}>
              <div className="formInput">
                <label style={{ fontSize: 14 }}>Physical Address: </label>
                <Typography
                  variant="subtitle2"
                  style={{ fontSize: 12, paddingLeft: 5 }}
                >
                  {internshipList[0].physical_address}
                </Typography>
              </div>
            </div>
          </div>
          <div
            className={styles.mainContent}
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <div className={styles.formSubContent}>
                <div className="formInput">
                  <label style={{ fontSize: 14 }}>
                    Industry Supervisor Name:{" "}
                  </label>
                  <Typography
                    variant="subtitle2"
                    style={{ fontSize: 12, paddingLeft: 5 }}
                  >
                    {internshipList[0].industry_supervisor_name}
                  </Typography>
                </div>
              </div>

              <div className={styles.formSubContent}>
                <div className="formInput">
                  <label style={{ fontSize: 14 }}>
                    Industry Supervisor Position:{" "}
                  </label>
                  <Typography
                    variant="subtitle2"
                    style={{ fontSize: 12, paddingLeft: 5 }}
                  >
                    {internshipList[0].industry_supervisor_position}
                  </Typography>
                </div>
              </div>

              <div className={styles.formSubContent}>
                <div className="formInput">
                  <label style={{ fontSize: 14 }}>
                    Industry Supervisor Phone:{" "}
                  </label>
                  <Typography
                    variant="subtitle2"
                    style={{ fontSize: 12, paddingLeft: 5 }}
                  >
                    {internshipList[0].industry_supervisor_phone}
                  </Typography>
                </div>
              </div>

              <div className={styles.formSubContent}>
                <div className="formInput">
                  <label style={{ fontSize: 14 }}>
                    Industry Supervisor Email:{" "}
                  </label>
                  <Typography
                    variant="subtitle2"
                    style={{ fontSize: 12, paddingLeft: 5 }}
                  >
                    {internshipList[0].industry_supervisor_email}
                  </Typography>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <div className={styles.formSubContent}>
                <div className="formInput">
                  <label style={{ fontSize: 14 }}>Progress Description:</label>
                  <Typography
                    variant="subtitle2"
                    style={{ fontSize: 12, paddingLeft: 5, minWidth: 200 }}
                  >
                    {internshipList[0].progress_description}
                  </Typography>
                </div>
              </div>
            </div>
          </div>
          <div
            className={styles.mainContent}
            style={{
              border: "none",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <div>
                <label>Lecturer Assigned:</label>
                <Typography
                  variant="subtitle1"
                  style={{ fontSize: 15, paddingLeft: 5 }}
                >
                  {employeeDetails ? (
                    employeeDetails.names.toUpperCase()
                  ) : (
                    <span>*********</span>
                  )}
                </Typography>
              </div>
            </div>
            <div
              style={{
                dispay: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                flexWrap: "nowrap",
              }}
            >
              <Button
                variant="contained"
                color="success"
                size="small"
                startIcon={<Edit />}
                onClick={() => {
                  navigate("/internship-edit", {
                    state: { internshipId: internshipList[0].id },
                  });
                }}
              >
                Edit
              </Button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default InternshipList;
