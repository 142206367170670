import { useEffect } from "react";
import { BrowserRouter, Route, Routes, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { login, logout } from "./redux/logger/loggerActions";
import Axios from "axios";
import axios from "axios";
import { basePath } from "./util/basePath";
import logo from "./assets/icon.png";
import LoginPage from "./components/login/LoginPage";
import NavBar from "./components/navbar";
import RegisteredUnits from "./pages/RegisteredUnits";
import InternshipList from "./pages/internship/InternshipList";
import InternshipAdd from "./pages/internship/InternshipAdd";
import InternshipEdit from "./pages/internship/InternshipEdit";
import ProjectAdd from "./pages/project/ProjectAdd";
import ProjectEdit from "./pages/project/ProjectEdit";
import ProjectList from "./pages/project/ProjectList";
import Home from "./pages/home/";
import ExamList from "./pages/ExamList";
function App() {
  const isLogged = useSelector((state) => {
    return state.logger.isLogged;
  });

  const url = basePath.url;

  const logger = useSelector((state) => {
    return state.logger;
  });

  const dispatch = useDispatch();

  Axios.defaults.withCredentials = true;

  useEffect(() => {
    Axios.get(`${url}login_get`)
      .then((result) => {
        // console.log(result.data);

        dispatch(login(result.data.user));
      })
      .catch((err) => {
        //console.log(err);
      });
  }, []);

  return (
    <>
      <BrowserRouter>
        {isLogged ? (
          <>
            <NavBar />
            <div
              style={{
                marginTop: 0,
                marginBottom: 10,
                fontWeight: "normal",
                fontFamily: "Verdana, Geneva, Tahoma, sans-serif",
                fontSize: 16,
                lineHeight: 1.6,
                wordSpacing: "normal",
                borderBottom: "1px solid #ccc",
                padding: 5,
                // backgroundColor: "#fff",
              }}
            >
              <div
                style={{
                  marginTop: 10,
                  marginBottom: 10,

                  padding: 2,
                  margin: "auto",
                  width: "90%",
                  boxSizing: "border-box",
                }}
              >
                <div
                  style={{
                    // marginTop: 10,
                    // marginBottom: 10,

                    // padding: 2,
                    // margin: "auto",
                    // width: "90%",y
                    // boxSizing: "border-box",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <img src={logo} height={20} width={20} />
                  </div>
                  <div style={{ paddingBottom: 5 }}>
                    &nbsp; The East African University
                  </div>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="column side">
                <h2 className="header_title"></h2>
              </div>
              <div className="column middle body_text">
                <Routes>
                  <Route path="/" element={<RegisteredUnits />} />
                  <Route path="/project" element={<ProjectList />} />
                  <Route path="/internship" element={<InternshipList />} />
                  <Route path="/internship-add" element={<InternshipAdd />} />
                  <Route path="/internship-edit" element={<InternshipEdit />} />
                  <Route path="/project-add" element={<ProjectAdd />} />
                  <Route path="/project-edit" element={<ProjectEdit />} />
                  <Route path="/exams" element={<RegisteredUnits />} />
                  {/* <Route path="/courses" element={<Courses />} />
                  <Route
                    path="/courses/course-preview/:id"
                    element={<CoursePreview />}
                  />
                  <Route path="/courses/my_courses" element={<MyCourses />} />
                  <Route
                    path="/courses/course-enrollment/:id"
                    element={<Enrollment />}
                  /> */}
                </Routes>
              </div>
              <div className="column right body_text">
                <h2 className="header_title">
                  {" "}
                  {logger.user.surname} {logger.user.otherNames}
                </h2>

                <p>Admission No: {logger.user.admissionNo} </p>

                <p>Sponsorship: {logger.user.sponsorship}</p>
                <p>Email: {logger.user.email}</p>
                <p>Mobile Number: {logger.user.mobileNo}</p>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    color: "crimson",
                  }}
                ></div>
              </div>
            </div>

            <div className="footer">
              <div style={{ textAlign: "center" }}>
                <p>&copy; 2023 Grahope Solutions, Inc.</p>
              </div>
            </div>
          </>
        ) : (
          <LoginPage />
        )}
      </BrowserRouter>
    </>
  );
}

export default App;
