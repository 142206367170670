import { useEffect, useState } from "react";
import { UseSelector, useSelector } from "react-redux/es/hooks/useSelector";
import { useNavigate } from "react-router-dom";
import { Typography, Button, CircularProgress } from "@mui/material";
import {
  ErrorOutlined,
  ArrowForward,
  Edit,
  Lock,
  LockOpen,
} from "@mui/icons-material";
import { basePath } from "../../util/basePath";
import styles from "../../styles/sidenav.module.css";
import axios from "axios";

import ErrorModal from "../../components/ErrorModal";

const ProjectList = () => {
  const [projectList, setProjectList] = useState(null);
  const [employeeDetails, setEmployeeDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentUserId, setCurrentUserId] = useState("");
  const [startDate, setStartDate] = useState("");

  const navigate = useNavigate();

  const user = useSelector((state) => {
    return state.logger.user;
  });

  useEffect(() => {
    setLoading(true);
    setError(null);

    // const aController = new AbortController();
    // fetch(`${basePath.url}internship/student`, {
    //   signal: aController.signal,
    //   method: "POST",
    //   body: JSON.stringify({ admissionNo: user.admissionNo }),
    //   headers: {
    //     Accept: "application/json",
    //     "Content-Type": "application/json",
    //   },
    // })
    //   .then((response) => {
    //     console.log(response);
    //     return response.json();
    //   })
    //   .then((result) => {
    //     console.log(`result: ${result.data.length}`);
    //     if (result.data.length > 0) {
    //       setProjectList(result.data);

    //       setCurrentUserId(result.data[0].lecturer_assigned_id);
    //       setLoading(false);
    //       setError(null);
    //     } else {
    //       setLoading(false);
    //       setError(null);
    //     }
    //   })
    //   .catch((err) => {
    //     if (err.name !== "AbortError") {
    //       setLoading(false);
    //       setError(error);
    //     }
    //   });

    // return () => {
    //   aController.abort();
    // };

    const getList = async () => {
      const list = await axios.post(`${basePath.url}project/student`, {
        data: user.admissionNo,
      });
      try {
        setProjectList(list.data.data);
        if (list.data.data.length > 0) {
          // console.log(res.data.data);
          const user_id = list.data.data[0].lecturer_assigned_id;

          // setCurrentUserId(user_id);
          if (list.data.data[0].lecturer_assigned_id) {
            const employee = await axios.get(
              `${basePath.url}users/get-by-user-id/${list.data.data[0].lecturer_assigned_id}`
            );

            if (employee.data.data.length > 0) {
              setEmployeeDetails(employee.data.data[0]);
            }
          }

          setLoading(false);
          setError(null);
        } else {
          setLoading(false);
          setError(null);
        }
      } catch (error) {
        setLoading(false);
        setError(error);
      }
    };
    getList();
  }, []);

  // useEffect(() => {
  //   //get lecturer assigned details
  //   const getEmployee = async () => {
  //     if (currentUserId) {
  //       const employee = await axios.get(
  //         `${basePath.url}users/get-by-user-id/${currentUserId}`
  //       );
  //       console.log(
  //         `employee details:${employee.data.data} user_id:${currentUserId}`
  //       );
  //       if (employee.data.data.length > 0) {
  //         setEmployeeDetails(employee.data.data);
  //       }
  //     }
  //   };

  //   getEmployee();
  // }, [projectList]);

  return (
    <>
      {/* <ErrorModal /> */}
      <div className={styles.topContent}>
        <Typography
          variant="h6"
          style={{ fontWeight: 400, color: "#282727", padding: 10 }}
        >
          Project / Proposal
        </Typography>
      </div>
      {loading === true ? (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "20px",
            marginTop: "50px",
          }}
        >
          <CircularProgress />
        </div>
      ) : !projectList || projectList.length < 1 ? (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "20px",
            marginTop: "50px",
          }}
        >
          <ErrorOutlined style={{ fontSize: 50 }} color="warning" />
          <Typography variant="h6" style={{ textAlign: "center" }}>
            You have not added Project / Proposal
          </Typography>
          <Button
            variant="outlined"
            color="success"
            size={"medium"}
            endIcon={<ArrowForward />}
            onClick={() => {
              navigate("/project-add");
            }}
          >
            Add New Project / Proposal
          </Button>
        </div>
      ) : (
        <>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              gap: 3,
            }}
          >
            {projectList[0].status === "OPEN" ? <LockOpen /> : <Lock />}
            <Typography
              variant="subtitle1"
              style={{ fontSize: 12, paddingRight: 10 }}
            >
              {projectList[0].status.toLowerCase()}
            </Typography>
          </div>
          <div
            className={styles.mainContent}
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <div
                className="search_box"
                style={{ width: "auto", marginRight: 10 }}
              >
                <label style={{ fontSize: 14 }}>Project Title :</label>
                <Typography
                  variant={"subtitle2"}
                  style={{ fontSize: 12, paddingLeft: 5 }}
                >
                  {projectList[0].project_title}
                </Typography>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <div
                className="search_box"
                style={{ width: "auto", marginRight: 10 }}
              >
                <label style={{ fontSize: 14 }}>Project Level :</label>
                <Typography
                  variant={"subtitle2"}
                  style={{ fontSize: 12, paddingLeft: 5 }}
                >
                  {projectList[0].project_level}
                </Typography>
              </div>

              <div
                className="search_box"
                style={{ width: "auto", marginRight: 10 }}
              >
                <label style={{ fontSize: 14 }}>Assessment Status :</label>
                <Typography
                  variant={"subtitle2"}
                  style={{ fontSize: 12, paddingLeft: 5 }}
                >
                  {projectList[0].assessment_status}
                </Typography>
              </div>

              <div
                className="search_box"
                style={{ width: "auto", marginRight: 10 }}
              >
                <label style={{ fontSize: 14 }}>Marks Reflected ?:</label>
                <Typography
                  variant={"subtitle2"}
                  style={{ fontSize: 12, paddingLeft: 5 }}
                >
                  {projectList[0].marks_reflected ? (
                    <span>True</span>
                  ) : (
                    <span>False</span>
                  )}
                </Typography>
              </div>
            </div>
          </div>
          <div className={styles.mainContent}>
            <div className="formInput">
              <label style={{ fontSize: 14 }}>Project Summary :</label>
              <Typography
                variant="subtitle2"
                style={{ fontSize: 12, paddingLeft: 5 }}
              >
                {projectList[0].project_summary}
              </Typography>
            </div>
          </div>

          <div className={styles.mainContent}>
            <div className="formInput">
              <label style={{ fontSize: 14 }}>Project Progress :</label>
              <Typography
                variant="subtitle2"
                style={{ fontSize: 12, paddingLeft: 5 }}
              >
                {projectList[0].project_progress}
              </Typography>
            </div>
          </div>

          <div
            className={styles.mainContent}
            style={{
              border: "none",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <div>
                <label>Lecturer Assigned:</label>
                <Typography
                  variant="subtitle1"
                  style={{ fontSize: 15, paddingLeft: 5 }}
                >
                  {employeeDetails ? (
                    employeeDetails.names.toUpperCase()
                  ) : (
                    <span>*********</span>
                  )}
                </Typography>
              </div>
            </div>
            <div
              style={{
                dispay: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                flexWrap: "nowrap",
              }}
            >
              <Button
                variant="contained"
                color="success"
                size="small"
                startIcon={<Edit />}
                onClick={() => {
                  navigate("/project-edit", {
                    state: { projectId: projectList[0].id },
                  });
                }}
              >
                Edit
              </Button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ProjectList;
