import { UseSelector, useDispatch } from "react-redux";
import { login, logout } from "../redux/logger/loggerActions";
import { Button } from "@mui/material";
import Axios from "axios";
import { basePath } from "../util/basePath";
import {
  Logout,
  LogoutOutlined,
  LogoutRounded,
  LogoutTwoTone,
} from "@mui/icons-material";
const NavBar = () => {
  const dispatch = useDispatch();

  const handleLogout = async () => {
    try {
      const signout = await Axios.get(`${basePath.url}logout`);
      dispatch(logout());
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <ul
      className="nav"
      style={{ borderBottom: "2px solid #ebd302", padding: 10 }}
    >
      <li>
        <Button
          style={{ color: "white", fontWeight: 600 }}
          size="medium"
          onClick={handleLogout}
          endIcon={<LogoutRounded size={80} style={{ fontWeight: 600 }} />}
        >
          Logout
        </Button>
      </li>

      {/* <li>
          <a href="#">Home</a>
        </li>
        <li className="active" style={{ float: "right" }}>
          <a href="#">Home</a>
        </li> */}
    </ul>
  );
};

export default NavBar;
