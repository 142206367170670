import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Field,
  FieldArray,
  Formik,
  useFormik,
  Form,
  ErrorMessage,
} from "formik";
import * as Yup from "yup";
import Axios from "axios";
import axios from "axios";
import styles from "../../styles/sidenav.module.css";

import { Button, CircularProgress, Typography } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import {
  CancelOutlined,
  CloseOutlined,
  PlusOne,
  AddRounded,
  AddCircleOutline,
} from "@mui/icons-material";

import ErrorText from "../../components/ErrorText";
import { basePath } from "../../util/basePath";
import ErrorModal from "../../components/ErrorModal";

const ProjectAdd = () => {
  const url = basePath.url;
  const user = useSelector((state) => {
    return state.logger.user;
  });

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [feedBack, setFeedback] = useState(null);

  const [lecturerList, setLecturerList] = useState(null);

  useEffect(() => {
    setLoading(true);
    setError(null);
    setFeedback(null);
    const empStatus = "all";
    const userStatus = "all";
    axios
      .get(`${url}users/get_users/${empStatus}/${userStatus}`)
      .then((res) => {
        let list = [];
        list = [...res.data.data];

        setLecturerList(list);

        setLoading(false);
        setError(null);
        setFeedback(null);
      })
      .catch((error) => {
        setLoading(false);
        setError("Error Loading Users");
        setFeedback(null);
      });
  }, []);

  const initialValues = {
    admission_number: user.admissionNo,
    project_level: "",
    project_title: "",
    project_summary: "",
    project_progress: "",
    lecturer_assigned_id: "",
    assessment_status: "",
    marks_reflected: "",
  };

  const validationSchema = Yup.object({
    admission_number: Yup.string().required("Admission Number not set"),

    project_level: Yup.string().required("Please select Project Level"),
    project_title: Yup.string().required("Please enter Project title"),
    project_summary: Yup.string().required("Please enter Project Summary"),
    marks_reflected: Yup.string().required("Please select Marks Status"),
    project_progress: Yup.string().required("Please select Project Progress"),
    assessment_status: Yup.string().required("Please select Assessment Status"),
  });

  const onSubmitProject = async (values, onSubmitProps) => {
    //console.log(values);
    setLoading(true);
    setError(null);
    setFeedback(null);

    const projectDetails = {
      admission_number: values.admission_number,
      project_level: values.project_level,
      project_title: values.project_title,
      project_summary: values.project_summary,
      project_progress: values.project_progress,
      lecturer_assigned_id: values.lecturer_assigned_id,
      assessment_status: values.assessment_status,
      marks_reflected: values.marks_reflected,
    };

    try {
      const addProject = await Axios.post(`${url}project/add`, projectDetails);
      //   console.log(addProject.data);
      setLoading(false);
      if (addProject.data.message === "error") {
        setError(addProject.data.data);
        setFeedback(null);
      } else {
        setError(null);
        setFeedback("Project added successfully!");
      }

      onSubmitProps.setSubmitting(false);
      onSubmitProps.resetForm();
    } catch (error) {
      setLoading(false);
      setError(error.message);
      setFeedback(null);
      onSubmitProps.setSubmitting(false);
    }
  };

  return (
    <>
      <div className={styles.topContent}>
        <Typography
          variant="h6"
          style={{
            fontWeight: 400,
            color: "#282727",
            padding: 10,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>Project / Proposal</div>{" "}
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              gap: 10,
            }}
          >
            <AddCircleOutline style={{ fontSize: 20 }} color="info" />
            <Typography variant="subtitle2" style={{ fontSize: 14 }}>
              Add
            </Typography>
          </div>
        </Typography>
      </div>

      <div>
        {error && (
          <div className="error_box">
            <Typography variant="subtitle2">{error}</Typography>
          </div>
        )}
      </div>
      <div>
        {feedBack && (
          <>
            <div className="success_box">
              <Typography variant="subtitle2">{feedBack}</Typography>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                variant="outlined"
                size="medium"
                color="success"
                onClick={() => {
                  return navigate("/");
                }}
                endIcon={<SendIcon />}
              >
                View Project / Proposal
              </Button>
            </div>
          </>
        )}
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "centr",
          alignItems: "center",
          width: "100%",
        }}
      >
        {loading ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: 50,
            }}
          >
            Loading.... <CircularProgress color={"primary"} size={25} />
          </div>
        ) : null}
      </div>

      <Formik
        initialValues={initialValues}
        onSubmit={onSubmitProject}
        validationSchema={validationSchema}
      >
        {({ isValid, isSubmitting }) => {
          return (
            <Form>
              <div
                className={styles.mainContent}
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <div
                    className="search_box"
                    style={{ width: "auto", marginRight: 10 }}
                  >
                    <label>
                      Lecturer Assigned :
                      <span style={{ color: "skyblue" }}>
                        (ignore if not assigned)
                      </span>
                    </label>
                    <Field
                      as="select"
                      name="lecturer_assigned_id"
                      id="lecturer_assigned_id"
                    >
                      <option value="">Select Lecturer Assigned</option>
                      {lecturerList
                        ? lecturerList
                            .filter((lec) => {
                              return (
                                lec.user_group === "Lecturer" ||
                                lec.user_group === "Hod" ||
                                lec.user_group === "Dean"
                              );
                            })
                            .sort((a, b) => {
                              let fa = a.names.toLowerCase();
                              let fb = b.names.toLowerCase();

                              if (fa > fb) {
                                return 1;
                              }
                              if (fa < fb) {
                                return -1;
                              }
                              return 0;
                            })
                            .map((lecturer) => {
                              return (
                                <option
                                  key={lecturer.user_id}
                                  value={lecturer.user_id}
                                >
                                  {lecturer.names.toUpperCase()}
                                </option>
                              );
                            })
                        : null}
                    </Field>
                    <ErrorMessage
                      name="lecturer_assigned_id"
                      component={ErrorText}
                    />
                  </div>
                  <div
                    className="search_box"
                    style={{ width: "auto", marginRight: 10 }}
                  >
                    <label>Project Level :</label>
                    <Field
                      as="select"
                      id="project_level"
                      name="project_level"
                      style={{ padding: 3 }}
                    >
                      <option value="">Select Project Level</option>
                      <option value="proposal">Proposal</option>
                      <option value="project">Project</option>
                    </Field>
                    <ErrorMessage name="project_level" component={ErrorText} />
                  </div>
                  {/* <div
                    className="search_box"
                    style={{ width: "auto", marginRight: 10 }}
                  >
                    <label>Expected Date of Completion </label>
                    <Field
                      type="date"
                      id="end_date"
                      name="end_date"
                      style={{ padding: 3 }}
                    />
                    <ErrorMessage name="end_date" component={ErrorText} />
                  </div> */}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  {/* <div
                    className="search_box"
                    style={{ width: "auto", marginRight: 10 }}
                  >
                    <label>Payment Status :</label>
                    <Field
                      as="select"
                      name="payment_status"
                      id="payment_status"
                    >
                      <option value="">Select Payment Status</option>
                      <option value="not paid">Not Paid</option>
                      <option value="paid">Paid</option>
                    </Field>
                    <ErrorMessage name="payment_status" component={ErrorText} />
                  </div> */}
                  <div
                    className="search_box"
                    style={{ width: "auto", marginRight: 10 }}
                  >
                    <label>Assessment Status :</label>
                    <Field
                      as="select"
                      name="assessment_status"
                      id="assessment_status"
                    >
                      <option value="">Assessment Status</option>
                      <option value="pending">Pending</option>
                      <option value="assessed">Assessed</option>
                    </Field>
                    <ErrorMessage
                      name="assessment_status"
                      component={ErrorText}
                    />
                  </div>
                  <div
                    className="search_box"
                    style={{ width: "auto", marginRight: 10 }}
                  >
                    <label>Marks Reflected? :</label>
                    <Field
                      as="select"
                      name="marks_reflected"
                      id="marks_reflected"
                    >
                      <option value="">Select option</option>
                      <option value="false">False</option>
                      <option value="true">True</option>
                    </Field>
                    <ErrorMessage
                      name="marks_reflected"
                      component={ErrorText}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.mainContent}>
                <div className="formInput" style={{ width: "100%" }}>
                  <label>Project Title:</label>
                  <Field
                    type="text"
                    id="project_title"
                    name="project_title"
                    placeholder="Project Title"
                    style={{ width: "100%" }}
                  />
                  <ErrorMessage name="project_title" component={ErrorText} />
                </div>
              </div>

              <div
                className={styles.mainContent}
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div className="formInput" style={{ width: "100%" }}>
                  <label>Project Summary:</label>
                  <Field
                    as="textarea"
                    id="project_summary"
                    name="project_summary"
                    placeholder="Project Summary"
                    style={{ fontSize: 12, minWidth: "100%" }}
                  />
                  <ErrorMessage name="project_summary" component={ErrorText} />
                </div>
              </div>
              <div
                className={styles.mainContent}
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div className="formInput" style={{ width: "100%" }}>
                  <label>Project Progress: </label>
                  <Field
                    as="textarea"
                    id="project_progress"
                    name="project_progress"
                    placeholder="Project Progress"
                    style={{ fontSize: 12, width: "90%" }}
                  />
                  <ErrorMessage name="project_progress" component={ErrorText} />
                </div>
              </div>

              <Button
                variant="contained"
                type="submit"
                endIcon={<SendIcon />}
                style={{ margin: 10 }}
                disabled={!isValid || isSubmitting}
              >
                Submit
              </Button>
              <Button variant="outlined" type="reset">
                Reset
              </Button>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default ProjectAdd;
