import React, { Component, useEffect, useState } from "react";
import { Typography } from "@mui/material";
import { basePath } from "../util/basePath";
import UploadAnswers from "./UploadAnswers";
const ExamList = ({ entry_id, unitId, unitName, unitCode, periodId }) => {
  const [lecUnitId, setLecUnitId] = useState(null);
  const [currentExamId, setCurrentExamId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [exams, setExams] = useState([]);

  const url = basePath.url;

  useEffect(() => {
    setError(null);
    setLoading(true);
    setLecUnitId(null);
    // console.log(
    //   `unitid:${unitId} unitName:${unitName} unitCode:${unitCode} periodID:${periodId}`
    // );
    let postData = {
      unitId: unitId,
      unitName: unitName,
      unitCode: unitCode,
      periodId: periodId,
    };
    fetch(`${url}get-lec-course_unit_id`, {
      method: "POST",
      headers: {
        Accept: "application.json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postData),
    })
      .then((res) => {
        if (res.status >= 400 && res.status <= 600) {
          throw new Error("Error Fetching Units");
        }
        return res.json();
      })
      .then((result) => {
        console.log(result.data);
        setLecUnitId(result.data);
        setError(null);
        setLoading(false);

        if (!result.data) {
          setError("Course Unit E-learning Not Set");
          setLoading(false);
        }
      })
      .catch((err) => {
        setError(err);
        alert(err.message);

        setLoading(false);
        setLecUnitId(null);
      });
  }, []);

  useEffect(() => {
    if (lecUnitId > 0) {
      setError(null);
      setLoading(true);
      setExams([]);
      fetch(`${url}get-e-exam/${lecUnitId}`, { method: "GET" })
        .then((res) => {
          if (res.status >= 400 && res.status <= 600) {
            throw new Error("Error Fetching Units");
          }
          return res.json();
        })
        .then((result) => {
          setExams(result.data);
          setError(null);
          setLoading(false);

          if (result.data.length < 1) {
            setError("Exam Not Set");
            setLoading(false);
          }
        })
        .catch((err) => {
          setError(err);
          alert(err.message);

          setLoading(false);
          setExams([]);
        });
    }
  }, [lecUnitId]);
  return (
    <>
      {/* <p>{lecUnitId}</p> */}
      {loading ? <div>Loading....</div> : null}
      {error ? (
        <div
          style={{
            padding: 10,
            width: "99%",
            margin: 10,
            boxSizing: "border-box",
            borderLeft: "3px solid crimson",
            backgroundColor: "#ffe3e3",
          }}
        >
          <Typography variant="subtitle2">
            {/* Course Unit E-Learning not set! */}
            {error}
          </Typography>
        </div>
      ) : null}

      {exams.map((exam, index) => {
        return (
          <>
            <UploadAnswers quizId={exam.entry_id} />
            {/* {exam.entry_id} */}
          </>
        );
      })}
    </>
  );
};

export default ExamList;
